<template>
  <div class="storeHome">
    <div class="top">
      <ShopCover
        :info="info"
        :categoryDatab="categoryDatab"
        :onlineTime="onlineTime"
        :categoryDataa="categoryDataa"
      ></ShopCover>
    </div>
    <!-- 店铺优惠券 -->
    <div class="nrong main-card" v-if="couponlist && couponlist.length != 0">
      <div style="margin-bottom: 10px">商店优惠券</div>
      <div class="coupons-list">
        <div style="overflow: hidden" id="btnBox">
          <div class="yuan-l" @click="handleLeft" v-if="juli != 0">
            <i style="font-size: 12px" class="el-icon-arrow-left"></i>
          </div>
          <div
            class="yuan-r"
            @click="handleRight"
            v-if="
              juli > -(Math.ceil(this.couponlist.length / 3) * 1148) + 1148 &&
              this.couponlist.length > 3
            "
          >
            <i style="font-size: 12px" class="el-icon-arrow-right"></i>
          </div>
          <div
            style="display: flex; align-items: center"
            :style="{ transform: `translateX(${juli}px)` }"
          >
            <CouponsVI
              v-for="item in couponlist"
              :key="item.id"
              :data="item"
            ></CouponsVI>
          </div>
        </div>
      </div>
    </div>
    <!-- <SlideText :type="1" :data="zjData"></SlideText> -->
    <template v-for="(item, index) in viewList">
      <div
        :key="index"
        :style="{ margin: index == 0 ? '0 auto' : '10px auto' }"
        style="width: 1240px"
      >
        <component
          class="asd"
          :data="item['data']"
          :is="typeList[item.index]['data'][item.type]['com']"
          :type="1"
          :info="info"
          :categoryDatab="categoryDatab"
          :onlineTime="onlineTime"
          :data-index="0"
          :categoryDataa="categoryDataa"
        ></component>
      </div>
    </template>
    <div class="shangp" id="goods-list">
      <div class="main-card" style="display: flex">
        <div class="left">
          <div class="l-all">
            <svg
              viewBox="0 0 12 10"
              class="
                shopee-svg-icon
                shopee-category-list__header-icon
                icon-all-cate
              "
              style="width: 12px; height: 16px; margin-right: 10px"
            >
              <g fill-rule="evenodd" stroke="none" stroke-width="1">
                <g transform="translate(-373 -208)">
                  <g transform="translate(155 191)">
                    <g transform="translate(218 17)">
                      <path
                        d="m0 2h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"
                      ></path>
                      <path
                        d="m0 6h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"
                      ></path>
                      <path
                        d="m0 10h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span>分类</span>
          </div>
          <div class="l-category">
            <div
              class="item"
              :class="index == 999 ? 'item-active' : ''"
              @click="handleClick({ id: 0, name: '全部' }, 999)"
            >
              <div style="width: 20px; height: 10px">
                <i class="el-icon-caret-right" v-if="index == 999"></i>
              </div>
              <div>
                <span>所有商品</span>
              </div>
            </div>
            <div
              class="item"
              v-for="(item, i) in categoryData"
              :key="item.category_id"
              :class="index == i ? 'item-active' : ''"
              @click="handleClick(item, i)"
            >
              <div style="width: 20px; height: 10px">
                <i class="el-icon-caret-right" v-if="index == i"></i>
              </div>
              <div>
                <span>{{ item.name }}</span>
              </div>
            </div>
            <!-- <div class="item" v-if="show">
              <div style="width: 20px; height: 10px"></div>
              <div
                @click="
                  categoryLists = categoryList.list;
                  show = false;
                "
              >
                <span
                  >更多
                  <i class="el-icon-arrow-down"></i>
                </span>
              </div>
            </div> -->
          </div>
        </div>
        <div class="right">
          <div v-if="searchGoodsData.list.length != 0">
            <div class="top">
              <div class="lefts">
                <span>筛选</span>
                <div
                  class="l-btn"
                  :class="sxIndex == i ? 'l-btn-active' : ''"
                  v-for="(item, i) in sxData"
                  :key="i"
                  @click="qiehuan(i)"
                >
                  {{ item.name }}
                </div>
                <div class="l-btna">
                  <span
                    :style="{
                      color: jgIndex == 0 || jgIndex == 1 ? '#ee4d2d' : '',
                    }"
                  >
                    {{ jgname }}</span
                  >

                  <i class="el-icon-arrow-down"></i>
                  <div class="jiageList">
                    <div
                      v-for="(item, i) in jgData"
                      :key="i"
                      @click="jiage(item, i)"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="zxc"
                    >
                      价格：{{ item.label }}
                      <i
                        v-if="jgIndex == i"
                        class="el-icon-check"
                        style="color: #ee4d2d"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="r-feny">
                <div style="width: 100px">
                  <span style="color: #ee4d2d"> {{ page }}</span
                  >/{{ Math.ceil(searchGoodsData.total / 70) }}
                </div>
                <el-pagination
                  background
                  :current-page="page"
                  :page-size="pageSize"
                  layout="prev, next"
                  :total="searchGoodsData.total"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </div>
            <div class="search-list">
              <SearchGoods
                :data="item"
                v-for="item in searchGoodsData.list"
                :key="item.id"
              ></SearchGoods>
            </div>
            <div
              style="display: flex; justify-content: center; margin-top: 40px"
              class="cvb"
            >
              <el-pagination
                background
                :current-page="page"
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="searchGoodsData.total"
                @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
          <div
            v-else
            style="
              font-siez: 18px;
              text-align: center;
              color: rgba(0, 0, 0, 0.54);
              margin-top: 140px;
            "
          >
            <img style="width: 134px; height: 134px" :src="quesheng" alt="" />
            <div>哎呀！我们找不到任何商品。试试关闭一些筛选？</div>
            <div style="margin: 10px 0">or</div>
            <div>
              <el-button type="primary">重设筛选</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scroll-top" @click="hanldeScroll">
      <i class="el-icon-caret-top"></i>
      <div style="font-size: 14px; margin-top: -10px">TOP</div>
    </div>
  </div>
</template>
<script>
import env from "@/settings/env.js";
import { storeIndex, categoryList, searchGoods } from "@/api/home.js";
import { getShopCouponList, couponReceive, getDecoration } from "@/api/buy.js";
import SearchGoods from "@/components/category/SearchGoods";
import CouponsVI from "@/components/marketing/CouponsVI";

// 店铺装饰组件
// 媒体相关组件
import ShopCover from "@/components/shopDecoration/ShopCover";
import BottomGoods from "@/components/shopDecoration/BottomGoods";
import Slide from "@/components/shopDecoration/Slide";
import Multigraph from "@/components/shopDecoration/Multigraph";
import Videos from "@/components/shopDecoration/Videos";
import SlideText from "@/components/shopDecoration/SlideText";
import Texts from "@/components/shopDecoration/Texts";

// 商品相关组件
import GoodsBright from "@/components/shopDecoration/goodsAndClass/GoodsBright";
import CommodityHighlights from "@/components/shopDecoration/goodsAndClass/CommodityHighlights";
import NewGoods from "@/components/shopDecoration/goodsAndClass/NewGoods";

import GoodsCategory from "@/components/shopDecoration/goodsAndClass/GoodsCategory";
import ImgCategory from "@/components/shopDecoration/goodsAndClass/ImgCategory";
import HotSales from "@/components/shopDecoration/goodsAndClass/HotSales";

export default {
  components: {
    SearchGoods,
    CouponsVI,
    ShopCover,
    SlideText,

    BottomGoods,
    Multigraph,
    Slide,
    Videos,
    Texts,
    GoodsBright,
    GoodsCategory,
    ImgCategory,
    HotSales,
  },
  data() {
    return {
      quesheng: require("@/assets/img/home/quexing.png"),
      fileUrl: env.fileUrlPrefix,
      info: [],
      categoryData: "",
      categoryDataa: [],
      categoryDatab: [],
      index: 999,
      searchGoodsData: {
        list: [],
      },
      sxData: [{ name: "热门" }, { name: "最新" }, { name: "最热销" }],
      jgData: [
        { label: "低至高", value: 0 },
        { label: "高至低", value: 1 },
      ],
      jgname: "价格",
      sxIndex: 0,
      jgIndex: 99,
      order: "", //价格排序
      page: 1, //页数
      pageSize: 70,
      category_id: 0,
      addTime: 0,
      onlineTime: 0,
      couponlist: [], //店铺优惠券列表
      juli: 0, //优惠券滑动距离
      zjData: {
        info: "",
      },
      typeList: [
        {
          title: "媒体组件及文字",
          show: 1,
          data: {
            slide: {
              name: "滑动式图片",
              max: 10,
              num: 0,
              key: "slide",
              com: Slide,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "slide",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    goods_title: "",
                  },
                ],
              },
            },
            multigraph: {
              name: "多张图片",
              max: 10,
              num: 0,
              key: "multigraph",
              com: Multigraph,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "multigraph",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                ],
              }, // 数据
            },
            video: {
              name: "视频",
              max: 3,
              num: 0,
              key: "video",
              com: Videos,
              data: {
                id: 0,
                block_layout: "",
                block_module_type: "video",
                block_text: "",
                block_content: {
                  file_id: 0,
                  video: "",
                  video_cover: "",
                  video_img: "",
                },
              }, // 数据
            },
            slide_text: {
              name: "滑动式图片和商店介绍文本",
              max: 1,
              num: 0,
              key: "slide_text",
              com: SlideText,
              data: {
                id: 0,
                block_layout: 1,
                block_module_type: "slide_text",
                block_text: "",
                block_content: [
                  {
                    file_id: 0,
                    image: "",
                    goods_id: [],
                    goods_title: [],
                  },
                ],
              }, // 数据
            },
            text: {
              name: "文字",
              max: 10,
              num: 0,
              key: "text",
              com: Texts,
              data: {
                id: 0,
                block_layout: "",
                block_module_type: "text",
                block_text: "",
                block_content: "",
              },
            },
          },
        },
        {
          title: "商品及分类",
          show: 1,
          data: {
            goods_light: {
              name: "商品亮点",
              max: 10,
              num: 0,
              key: "goods_light",
              com: CommodityHighlights,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_light",
                block_text: "",
                goodsList: [
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    file_id: 0,
                    image: "",
                    images: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                ],
                block_content: [],
              },
            },
            goods_cate: {
              name: "商品-按分类",
              max: 10,
              num: 0,
              key: "goods_cate",
              com: GoodsCategory,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_cate",
                block_text: "",
                block_content: {
                  cate_id: 0,
                  cate_name: "",
                  cate_name_log: "",
                },
                goods_list: 6,
              },
            },
            goods_hot: {
              name: "热门商品",
              max: 10,
              num: 0,
              key: "goods_hot",
              com: GoodsBright,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_hot",
                block_text: "标题",
                goodsList: [
                  // {
                  //   image: "",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  // {
                  //   image: "0",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  // {
                  //   image: "0",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  // {
                  //   image: "0",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                ],
                block_content: [],
              },
            },
            goods_new: {
              name: "新商品",
              max: 1,
              num: 0,
              key: "goods_new",
              com: NewGoods,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "goods_new",
                block_text: "新商品",
                goodsList: [
                  {
                    image: "",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  {
                    image: "0",
                    goods_id: "",
                    title: "",
                    price: 0,
                  },
                  // {
                  //   image: "0",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                  // {
                  //   image: "0",
                  //   goods_id: "",
                  //   title: "",
                  //   price: 0,
                  // },
                ],
                block_content: [],
              },
            },
            image_cate: {
              name: "图片分类列表",
              max: 1,
              num: 0,
              key: "image_cate",
              com: ImgCategory,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "image_cate",
                block_text: "分类",
                block_content: [
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                  {
                    category_id: 0,
                    category_name: "",
                    category_name_log: "",
                    file_id: 0,
                    image: "",
                    images: "",
                  },
                ],
              },
            },
          },
        },
        {
          title: "促销",
          show: 1,
          data: {
            hot_promotion: {
              name: "热门促销",
              max: 1,
              num: 0,
              key: "hot_promotion",
              com: HotSales,
              data: {
                id: 0,
                block_layout: 0,
                block_module_type: "hot_promotion",
                block_text: "热门促销",
                block_content: [],
                goodsList: [
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                  {
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  },
                ],
              },
            },
          },
        },
        {
          title: "隐藏",
          show: 1,
          data: {
            shop_cover: {
              name: "店铺分娩",
              max: 1,
              num: 1,
              key: "shop_cover",
              com: ShopCover,
            },
          },
        },
      ],
      viewList: [
        // {
        //   title: "店铺封面",
        //   index: 3,
        //   type: "shop_cover",
        //   data: {
        //     block_layout: 1,
        //     block_module_type: "shop_cover",
        //     block_text: "",
        //     block_content: [
        //       {
        //         file_id: 0,
        //         image: "",
        //         goods_id: [],
        //         goods_title: [],
        //       },
        //     ],
        //   },
        // },
      ],
    };
  },
  created() {
    this.getZxInfo();
    this.$store.state.searchType = 0;
    this.$store.state.searchValue = "";
    // this.addTime = this.$route.query.addTime;
    // this.onlineTime = this.$route.query.onlineTime;
    this.getStoreIndex();
    this.getCategoryList();
    this.getGoodsList();

    this.getCouponList();
  },
  destroyed() {
    sessionStorage.removeItem("CHATINFO");
  },
  methods: {
    getZxInfo() {
      getDecoration({
        shop_id: this.$route.query.shop_id,
      }).then((res) => {
        if (res.code == 1) {
          let arr = [];
          res.data.forEach((item) => {

            let index = 0;
            let title = "";
            item.block_layout = Number(item.block_layout);
            if (item.block_content.constructor === Array) {
              if (
                item.block_module_type == "goods_light" ||
                item.block_module_type == "goods_hot" ||
                item.block_module_type == "goods_new" ||
                item.block_module_type == "image_cate" ||
                item.block_module_type == "hot_promotion"
              ) {
                item.goodsList = [];
              }

              item.block_content.forEach((val) => {
                val.images = val.image;
                val.image = val.short_image;
                val.goods_images = val.goods_image;

                if (
                  item.block_module_type == "goods_light" ||
                  item.block_module_type == "goods_hot" ||
                  item.block_module_type == "goods_new" ||
                  item.block_module_type == "image_cate" ||
                  item.block_module_type == "hot_promotion"
                ) {
                  val.title = val.goods_title;
                  item.goodsList.push(val);
                } else {
                  val.goods_id = val.goods_id.split(",");
                  val.goods_title = val.goods_title.split(",");
                }
              });
              if (
                item.block_module_type == "goods_light" ||
                item.block_module_type == "goods_hot" ||
                item.block_module_type == "goods_new"
              ) {
                // if (item.goodsList.length < 4) {
                //   for (let i = 0; i <= 5 - item.goodsList.length; i++) {
                //     item.goodsList.push({
                //       file_id: 0,
                //       image: "",
                //       images: "",
                //       goods_id: "",
                //       title: "",
                //       price: 0,
                //     });
                //   }
                // }
              } else if (item.block_module_type == "hot_promotion") {
                for (let i = 0; i < 8 - item.goodsList.length; i++) {
                  item.goodsList.push({
                    discount_id: 0,
                    goods_id: "",
                    goods_title: "",
                    goods_image: "",
                    goods_images: "",
                    goods_price: "",
                    discount_price: "",
                    discount_ration: "",
                  });
                }
              }
            }

            if (item.block_content.constructor === Object) {
              item.block_content.video_img = item.block_content.video_cover;
              if (item.block_module_type == "goods_cate") {
                item.goods_list = 6;
              }
            }

            for (let i = 0; i < 3; i++) {
              if (this.typeList[i].data[item.block_module_type]) {
                index = i;
                title = this.typeList[i].data[item.block_module_type].name;
              }
            }

            arr.push({
              title: title,
              index: index,
              type: item.block_module_type,
              data: item,
            });
          });
          // 处理已添加组件数量
          arr.forEach((item) => {
            if (this.typeList[item.index].data[item.type]) {
              this.typeList[item.index].data[item.type].num += 1;
            }
          });

          this.viewList.push.apply(this.viewList, arr);
        }
      });
    },
    // 获取店铺信息
    getStoreIndex() {
      storeIndex({ shop_id: this.$route.query.shop_id }).then((res) => {
        if (res.code == 1) {
          this.info = res.data;

          // 创建聊天对象
          sessionStorage.CHATINFO = JSON.stringify({
            shop_id: this.info.id,
            shop_name: this.info.shop_name,
            shop_avatar: this.info.shop_avatar,
          });
          if (res.data.carousel) {
            this.info.carousel = res.data.carousel.split(",");
          } else {
            this.info.carousel = [];
          }
          this.addTime = this.difference(
            this.info.join_time * 1000,
            new Date().getTime()
          );
          this.onlineTime = this.difference(
            this.info.online_time * 1000,
            new Date().getTime()
          );
          this.zjData.info = this.info;
        }
      });
    },
    // 获取店铺优惠券列表
    getCouponList() {
      getShopCouponList({
        shop_id: this.$route.query.shop_id,
      }).then((res) => {
        if (res.code == 1) {
          this.couponlist = res.data;
        }
      });
    },
    // 领取优惠券
    receive(id) {
      couponReceive({
        type: 1,
        keyword: id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.couponlist.forEach((item) => {
            if (item.id == id) {
              item.receiveCoupon = {
                coupon_id: item.id,
                state: "1",
              };
            }
          });
          this.$forceUpdate();
        }
        if (res.code == -1) {
          this.$router.push("/buyLogin");
        }
      });
    },
    // 获取店铺分类
    getCategoryList() {
      categoryList({ shop_id: this.$route.query.shop_id }).then((res) => {
        if (res.code == 1) {
          this.categoryData = res.data;
          res.data.forEach((e, i) => {
            if (i > 3) {
              this.categoryDatab.push(e);
            } else {
              this.categoryDataa.push(e);
            }
          });
        }
      });
    },
    // 获取店铺商品
    getGoodsList() {
      searchGoods({
        goods_status: "0,1",
        shop_id: this.$route.query.shop_id,
        category_id: this.category_id,
        type: this.sxIndex,
        order: this.order,
        page: this.page,
        page_size: this.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.searchGoodsData = res.data;
        }
      });
    },
    // 分类切换
    handleClick(data, index) {
      this.page = 1;
      this.index = index;
      this.category_id = data.id;
      this.getGoodsList();
    },
    // 筛选类型切换
    qiehuan(index) {
      this.page = 1;
      this.sxIndex = index;
      this.getGoodsList();
    },
    // 价格排序
    jiage(data, index) {
      this.page = 1;
      this.jgname = "价格" + data.label;
      this.jgIndex = index;
      this.sxIndex = 3;
      this.order = data.value;
      this.getGoodsList();
    },
    // 分页
    pageChange(page) {
      this.page = page;
      this.getGoodsList();
    },
    handleSelect(id) {
      this.category_id = id;
      if (id == 0) {
        this.index = 999;
      } else {
        this.categoryData.forEach((item, i) => {
          if (item.id == id) {
            this.index = i;
          }
        });
      }
      let box = document.getElementById("goods-list");

      // let time = setInterval(function () {

      //   if (document.documentElement.scrollTop <= box.offsetTop - 200) {
      //     document.documentElement.scrollTop += 100;
      //   } else {
      //     clearInterval(time);
      //   }
      // }, 10);
      window.scrollTo(0, box.offsetTop - 100);
      this.getGoodsList();
    },
    toGoodsInfo(id) {
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
    difference: function (beginTime, endTime) {
      var dateDiff = endTime - beginTime; //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      // var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      // var seconds = Math.round(leave3 / 1000);

      let date =
        dayDiff != 0
          ? dayDiff + "天"
          : "" + hours != 0
          ? hours + "小时"
          : "" + minutes + "分钟";
      return date;
    },
    handleLeft() {
      if (this.juli < 0) {
        this.juli = this.juli + 1148;
      }
    },
    handleRight() {
      let num = parseInt(this.couponlist.length / 3) * 1148;
      if (this.juli > -num) {
        this.juli = this.juli - 1148;
      }
    },
    hanldeScroll() {
      let time = setInterval(function () {
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(time);
        }
      }, 10);
    },
  },
};
</script>
<style lang="less" scoped>
.yus:hover {
  color: #ee4d2d;
  cursor: pointer;
}
.storeHome {
  padding-bottom: 120px;
  background: #f5f5f5;
  .scroll-top {
    bottom: 100px;
    right: 20px;
    position: fixed;
    color: #555;
    font-size: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background: red;
    justify-content: center;
    cursor: pointer;
  }
  .scroll-top:hover {
    color: #ee4d2d;
  }

  .top {
    background: #fff;
    padding-top: 40px;
    margin-bottom: 10px;

    .left {
      width: 390px;
      height: 134px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      .content {
        position: absolute;
        z-index: 2;
        padding: 20px;
        display: flex;
      }
    }
    .right {
      margin-left: 20px;
      padding-top: 20px;
      font-size: 14px;
    }
    .active {
      border-bottom: 2px solid #ee4d2d;
      color: #ee4d2d;
    }
    .item:hover {
      cursor: pointer;
      color: #ee4d2d;
    }
    #yus:hover {
      color: #ee4d2d;
    }
  }
  .nrong {
    margin-top: 20px;
    padding: 20px 30px;
    background: #fff;
    .coupons-list {
      // display: flex;
      // align-items: center;
      position: relative;
      // overflow: hidden;
      .yuan-l {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 54px;
        z-index: 22;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .yuan-r {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        right: -20px;
        top: 54px;
        z-index: 22;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .banner {
      display: flex;
    }
  }
  #btnBox:hover + .yuan-r {
    transform: scale(2, 2);
  }
  .yuan-r:hover {
    // font-size: 20px;
    transform: scale(2, 2);
    cursor: pointer;
  }
  #btnBox:hover + .yuan-l {
    transform: scale(2, 2);
  }
  .yuan-l:hover {
    // font-size: 20px;
    transform: scale(2, 2);
    cursor: pointer;
  }
  .shangp {
    margin-top: 40px;
    .left {
      width: 190px;
      padding-bottom: 60px;
      margin-right: 20px;
      .l-all {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom: 20px;
        // cursor: pointer;
      }
      .l-category {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom: 20px;
        .item {
          display: flex;
          font-size: 14px;
          padding: 8px 10px;
          color: rgba(0, 0, 0, 0.87);
          cursor: pointer;
        }
        .item-active {
          color: #ee4d2d;
        }
      }
      .l-price {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 10px 0 20px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        .el-input--mini .el-input__inner {
          padding: 6px;
        }
      }
      .l-evaluation {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 10px 0 20px 0;
        font-size: 14px;
        .item {
          padding: 4px 0;
          font-size: 12px;
          .el-rate__icon {
            font-size: 16px;
            margin: 2px;
          }
          .el-icon-star-off {
            font-size: 14px;
            padding: 0 1px;
          }
        }
      }
      .l-condition {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 10px 0 20px 0;
        font-size: 14px;
      }
    }
    .right {
      width: 990px;
      .top {
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        height: 60px;
        width: 100%;
        padding: 0 20px;
        align-items: center;
        .lefts {
          width: 100%;
          display: flex;
          font-size: 14px;
          color: #555;
          height: 100%;
          align-items: center;
          .l-btn {
            width: 90px;
            text-align: center;
            line-height: 34px;
            height: 34px;
            background: #fff;
            margin-left: 20px;
            cursor: pointer;
          }
          .l-btna {
            width: 200px;
            padding: 0 10px;
            box-sizing: border-box;
            line-height: 34px;
            height: 34px;
            background: #fff;
            margin-left: 20px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .jiageList {
              position: absolute;
              bottom: -69px;
              left: 0;
              background: #fff;
              width: 100%;
              padding: 0 10px;
              display: none;
              z-index: 3;
              .zxc:hover {
                color: #ee4d2d;
              }
            }
          }
          .l-btna:hover .jiageList {
            display: block;
          }
          .l-btn-active {
            background: #ee4d2d;
            color: #fff;
          }
        }
        .r-feny {
          display: flex;
          align-items: center;
          text-align: right;
          font-size: 14px;
        }
      }

      .search-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      .cvb {
        .el-pager li {
          background: transparent;
        }
        .el-pagination button:disabled {
          background: transparent;
        }
        .el-pagination .btn-next,
        .el-pagination .btn-prev {
          background: transparent;
        }
        .el-pager li.active {
          background: #ee4d2d;
          color: #fff;
        }
      }
    }
  }
}
</style>